import { some, includes } from 'lodash';

export class TimelineFilter {
  constructor() {
    this.bindSwitches();
  }

  bindSwitches(): void {
    $(document).on('change', '[data-timeline-filter-switch]', () => {
      const filterState: string[] = $('[data-timeline-filter-switch]')
        .filter((_i, el): boolean => $(el).prop('checked'))
        .map((_i, el): string => $(el).data('timelineFilterSwitch'))
        .get();
      $('[data-timeline-content]').each((_i, el) => {
        const $element = $(el);
        const $rootContainer = $element.closest('.timeline-cell');
        const tags = [];

        $element.find('.badge').each((_i, el) => {
          const $tag = $(el);
          const name = $tag.text();

          tags.push(name);

          includes(filterState, name) ? $tag.show() : $tag.hide();
        })

        if (some(tags, tag => includes(filterState, tag))) {
          $element.show();
          $rootContainer.show();
          return;
        }
        if ($element.siblings(':not(:hidden)').length === 0) {
          $rootContainer.hide();
          return;
        }
        const $parentContainer = $element.parent();
        const $cachedElement = $element.detach();
        $parentContainer.append($cachedElement);
        $cachedElement.hide();
      });
    });
  }
}

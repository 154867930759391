/* eslint no-console:0 */

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../images', true);

// TODO: remove full package and using custom build
import 'bootstrap/js/src/alert';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/tooltip';
import 'bootstrap/js/src/popover';

import '../stylesheets/application.scss';

import '../utilities/sticky-footer';
import '../initializers/billboard-slider';
import '../initializers/org-chart';
import '../initializers/embed-map';

import { TimelineFilter } from '../components/timeline-filter';

$(document).on('turbolinks:load', (): void => {
  $('#timeline-filter').each(function() { new TimelineFilter() });
});

import { forEach as _forEach } from 'lodash';
import { simpleFormat } from '../utilities/simple-format';
import { NuclearPoint, NuclearType } from '../types/nuclear-point';

const initConfig: google.maps.MapOptions = {
  center: {
    lat: 23.75,
    lng: 121,
  },
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeId: 'terrain',
  streetViewControl: false,
};

const legends = {
  [NuclearType.Plant]: {
    type: 'dark',
    name: NuclearType.Plant,
    icon: require('../images/map-icons/plant.png'),
  },
  [NuclearType.PlantDeprecated]: {
    type: 'secondary',
    name: NuclearType.PlantDeprecated,
    icon: require('../images/map-icons/plant-deprecated.png'),
  },
  [NuclearType.Storage]: {
    type: 'info',
    name: NuclearType.Storage,
    icon: require('../images/map-icons/storage.png'),
  },
  [NuclearType.StorageCandidate]: {
    type: 'light',
    name: NuclearType.StorageCandidate,
    icon: require('../images/map-icons/storage-candidate.png'),
  },
  [NuclearType.LowDisposal]: {
    type: 'warning',
    name: NuclearType.LowDisposal,
    icon: require('../images/map-icons/low-disposal.png'),
  },
  [NuclearType.LowDisposalCandidate]: {
    type: 'light',
    name: NuclearType.LowDisposalCandidate,
    icon: require('../images/map-icons/low-disposal-candidate.png'),
  },
  [NuclearType.HighDisposal]: {
    type: 'danger',
    name: NuclearType.HighDisposal,
    icon: require('../images/map-icons/high-disposal.png'),
  },
  [NuclearType.HighDisposalCandidate]: {
    type: 'light',
    name: NuclearType.HighDisposalCandidate,
    icon: require('../images/map-icons/high-disposal-candidate.png'),
  },
};

const getNuclearType = (point: NuclearPoint): string => point.is_deactivated ? NuclearType.PlantDeprecated : point.nuclear_type;

const linkTo = (text: string, url: string): string => `<a class="bold" href=${url} rel="noopener noreferrer" target="nonuke">${text} <span class="fad fa-external-link-alt"></span></a>`
const renderListCell = (show = true, term: string, description): string => show ? `
  <dt class="col-sm-3 text-primary">${term}</dt>
  <dd class="col-sm-9">${description}</dd>
` : '';
const renderInfoItem = (term: string, description): string => `
  <dt class="col-sm-4 text-primary">${term}</dt>
  <dd class="col-sm-8">${description}</dd>
`;
const renderInfoContent = (point: NuclearPoint): string => {
  const {
    name,
    purpose,
    progress,
    operator,
    note,
    link,
    activated_on_text: activatedOn,
    deactivated_on_text: deactivatedOn,
    low_level_count: lowLevelCount,
    high_level_count: highLevelCount,
  } = point;
  return `
    <div class="gmap-info-window container-fluid mx-n2">
      <div class="d-flex align-items-center flex-wrap mt-1 mb-3 pb-3 border-bottom">
        <h4 class="my-0 mr-2">${name}</h4>
        <span class="badge badge-${legends[getNuclearType(point)].type}">
          ${legends[getNuclearType(point)].name}
        </span>
      </div>
      <dl class="row mb-0">
        ${renderListCell(!!purpose, '設施用途', purpose)}
        ${renderListCell(!!activatedOn, '啟用日期', simpleFormat(activatedOn))}
        ${renderListCell(
          !!deactivatedOn,
          '退役日期',
          simpleFormat(deactivatedOn)
        )}
        ${renderListCell(!!lowLevelCount, '低階核廢料(桶)', lowLevelCount)}
        ${renderListCell(!!highLevelCount, '高階核廢料(束)', highLevelCount)}
        ${renderListCell(!!progress, '核廢進程', progress)}
        ${renderListCell(!!operator, '經營者', operator)}
        ${renderListCell(!!note, '備註', note)}
      </dl>
      ${!!link ? linkTo('更多內容', link) : ''}
    </div>
  `;
};

enum DataProperty {
  Name = '斷層',
  Type = '類別',
  observe = '類型',
  File = '詳細',
};
const renderDataInfoWindow = (feature: google.maps.Data.Feature): string => {
  const list = [];

  feature.forEachProperty((value, name) => {
    const content = name === 'File' ? linkTo('斷層個論', value) : value;
    list.push(renderInfoItem(DataProperty[name], content));
  });

  return `
    <div class="gmap-info-window container-fluid mx-n2">
      <dl class="row mb-0">
        ${list.join('')}
      </dl>
    </div>
  `;
};

const setStrokeColor: google.maps.Data.StylingFunction = (feature) => {
  const geoType = feature.getProperty('Type');

  if (geoType === '第一類') {
    return {
      strokeColor: '#dc3545',
    };
  }
  return {
    strokeColor: '#fd7e14',
  };
};

const initApi = async (): Promise<void> => {
  const $html = $('html');

  if ($html.hasClass('maps-api-loaded')) return;

  await $.ajax({
    dataType: 'script',
    cache: true,
    url: `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}`,
  });

  $html.addClass('maps-api-loaded');
};

const initMap = async (): Promise<void> => {
  const $components = $('#embed-map');

  if ($components.length <= 0) return;

  await initApi();

  const config = { ...initConfig, zoom: $(window).outerWidth() < 1366 ? 7 : 8 };

  $components.each((_index, embedMap) => {
    let activeInfoWindow;
    const $this = $(embedMap);
    const map = new google.maps.Map(embedMap, config);
    const legendContainer = $(`
      <ul class="list-unstyled border shadow-sm mx-2 p-2 embed-map-legend collapsed" id="embed-map-legend">
        <li>
          <button type="button" role="button" class="embed-map-legend__toggle text-secondary">
            <div class="d-flex align-items-center">
              <i class="open-toggle fad fa-2x fa-list-ul"></i>
              <span class="open-text">顯示圖例</span>
              <i class="close-toggle fad fa-2x fa-minus-hexagon text-danger"></i>
            </div>
          </button>
        </li>
        </ul>
    `)[0];

    _forEach(legends, ({ name, icon, type }) => {
      const li = document.createElement('li');
      li.className = 'd-flex align-items-center embed-map-legend__item';
      li.innerHTML = `<img class="mr-3" src="${icon}"><span class="badge badge-${type}">${name}</span>`;
      legendContainer.appendChild(li);
    });

    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legendContainer);
    map.data.loadGeoJson(
      'https://demeter.5fpro.com/geo/fault_zones.json?' + (new Date().getTime())
    );
    map.data.setStyle(setStrokeColor);
    map.data.addListener('mouseover', ({ feature }): void => {
      map.data.revertStyle();
      map.data.overrideStyle(feature, { strokeWeight: 6 });
    })
    map.data.addListener('mouseout', (): void => { map.data.revertStyle() });
    map.data.addListener('click', ({latLng, feature }): void => {
      if (activeInfoWindow) activeInfoWindow.close();
      const infoWindow = new google.maps.InfoWindow({
        content: renderDataInfoWindow(feature),
        maxWidth: 200,
        position: latLng,
      });
      infoWindow.open(map);
      activeInfoWindow = infoWindow;
    });

    $this.data('locations').forEach((point): void => {
      const options: google.maps.MarkerOptions = {
        map,
        title: point.name,
        position: new google.maps.LatLng(point.lat, point.lon),
        icon: legends[getNuclearType(point)].icon,
      };
      const marker = new google.maps.Marker(options);
      const infoWindow = new google.maps.InfoWindow({
        content: renderInfoContent(point),
        maxWidth: 520,
      });
      marker.addListener('click', () => {
        if (activeInfoWindow) activeInfoWindow.close();
        infoWindow.open(map, marker);
        activeInfoWindow = infoWindow;
      });
    });
  });
};

$(document).on(
  'turbolinks:load',
  async (): Promise<void> => {
    initMap();
  }
);

$(document).on('click', '.embed-map-legend__toggle', (): void => {
  $('.embed-map-legend').toggleClass('collapsed');
});

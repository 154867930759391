const renderAnchor = (text: string, url: string): string => `
  <div>
    <strong>
      <a href="${url}" rel="noopener noreferrer" target="_blank">
        ${text}
        <span class="fad fa-external-link-alt ml-1"></span>
      </a>
    </strong
  </div>
`;
const renderMembers = (text: string): string => {
  if (!text) return "";

  const term = '組織成員';

  if (/http/.test(text)) return renderAnchor(term, text);

  return `
    <div><strong>${term}</strong></div>
    <div>${text}</div>
  `;
};
const renderUrl = (url: string): string => {
  if (!url) return "";

  return renderAnchor('官網', url);
};

$(document).on('turbolinks:load', (): void => {
  const $selector = $('.org-chart__anchor[data-description]');
  $selector.popover({
    html: true,
    content() {
      $selector.not(this).popover('hide');
      const $this = $(this);
      const members = $this.data('members');
      const url = $this.data('url');
      return `
        <p>${$this.data('description')}</p>
        ${renderMembers(members)}
        ${renderUrl(url)}
      `;
    }
  });
});

import 'tiny-slider/dist/tiny-slider.css';
import { tns } from 'tiny-slider/src/tiny-slider';

const container = '#billboard-slider';

$(document).on('turbolinks:load', (): void => {
  $(container).each((_index, element): void => {
    if ($(element).children().length <= 0) return;

    tns({
      container,
      items: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      mode: 'gallery',
      controls: false,
      nav: false,
      speed: 1000,
      autoplayHoverPause: true
    })
  });
});

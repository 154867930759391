export enum NuclearType {
  Plant = "核電廠",
  PlantDeprecated = "已除役核電廠",
  Storage = "核廢料貯存場",
  StorageCandidate = "核廢料貯存場候選場址",
  LowDisposal = "低放射性廢棄物最終處置場",
  LowDisposalCandidate = "低放射性廢棄物最終處置場候選場址",
  HighDisposal = "高放射性廢棄物最終處置場",
  HighDisposalCandidate = "高放射性廢棄物最終處置場候選場址",
};

export type NuclearPoint = {
  nuclear_type: NuclearType;
  name: string;
  lon: number;
  lat: number;
  purpose?: string;
  activated_on_text?: string;
  activated_on?: Date;
  deactivated_on_text?: string;
  deactivated_on?: Date;
  low_level_count?: number;
  high_level_count?: number;
  progress?: string;
  is_deactivated?: boolean;
  operator?: string;
  link?: string;
  note?: string;
};
